body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.ui.justified.container {
  margin-left: 0px !important;
}

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.main.container {
  padding-top: 7em;
  flex: 1;
}

.wireframe {
  margin-top: 2em;
}

.ui.footer.segment {
  margin: 5em 0em 0em;
  padding: 5em 0em;
}

@media print {
  .hide-print {
    display: none !important;
  }

  .layout {
    margin-left: 0px !important;
  }

  pre {
    white-space: pre-wrap !important;
  }

  .headerAndContent {
    margin-left: 0px !important;
  }

  .print-page-break {
    page-break-before: always;
  }
}

code,
kbd,
pre,
samp {
  font-size: 0.85em;
}

.backface-visibility-hidden {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

div.button-container {
  float: left;
  width: 100%;
  margin-top: 15px;
}

div.button-container button {
  width: auto;
  padding: 7px 22px;
}

.flipper-container {
  z-index: 1;
  float: left;
  width: 250px;
  margin-right: 15px;
  display: block;
  -webkit-transition: height 500ms cubic-bezier(0.77, 0, 0.02, 1);
  -moz-transition: height 500ms cubic-bezier(0.77, 0, 0.02, 1);
  -ms-transition: height 500ms cubic-bezier(0.77, 0, 0.02, 1);
  -o-transition: height 500ms cubic-bezier(0.77, 0, 0.02, 1);
  transition: height 500ms cubic-bezier(0.77, 0, 0.02, 1);
  perspective: 1000px;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
}

.flipper-container span {
  color: white;
}

.flipper-container > div.flipper {
  z-index: 1;
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
}

.flipper-container > div.flipper .front,
.flipper-container > div.flipper .back {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -webkit-transition: -webkit-transform ease 500ms;
  -moz-transition: -webkit-transform ease 500ms;
  -ms-transition: -webkit-transform ease 500ms;
  -o-transition: -webkit-transform ease 500ms;
  transition: -webkit-transform ease 500ms;
  -webkit-transition: transform ease 500ms;
  -moz-transition: transform ease 500ms;
  -ms-transition: transform ease 500ms;
  -o-transition: transform ease 500ms;
  transition: transform ease 500ms;
}

.flipper-container > div.flipper .front {
  height: auto;
}

.flipper-container > div.flipper .back {
  height: auto;
}

.flipper-container .tile {
  color: white;
  padding: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.flipper-container.horizontal .front {
  transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}

.flipper-container.horizontal .back {
  transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
}

.flipper-container.horizontal div.flipper.flipped .front {
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.flipper-container.horizontal div.flipper.flipped .back {
  transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}

.flipper-container.vertical .front {
  transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
}

.flipper-container.vertical .back {
  transform: rotateX(-180deg);
  -ms-transform: rotateX(-180deg);
  -webkit-transform: rotateX(-180deg);
  -o-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
}

.flipper-container.vertical div.flipper.flipped .front {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
}

.flipper-container.vertical div.flipper.flipped .back {
  transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
}

.sketch-picker .saturation-white {
  background: -webkit-linear-gradient(to right, #fff, rgba(255,255,255,0));
  background: linear-gradient(to right, #fff, rgba(255,255,255,0));
}

.sketch-picker .saturation-black {
  background: -webkit-linear-gradient(to top, #000, rgba(0,0,0,0));
  background: linear-gradient(to top, #000, rgba(0,0,0,0));
}
