.home-wrap {
  width: 68px;
  height: 94px;
  padding: 0;
  overflow: hidden;
}
.home-frame {
  overflow: hidden;
  cursor: pointer;
  width: 340px;
  height: 470px;
  border: 0;
  position: relative;
  overflow: hidden;
  -ms-transform: scale(0.2);
  -moz-transform: scale(0.2);
  -o-transform: scale(0.2);
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}