.front .designerHoverItem {
  border: 1px dotted transparent;
  pointer-events: auto;
}

.front .designerHoverItem:hover {
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  border: 1px dotted black;
  pointer-events: auto;
}

.back .designerHoverItem {
  border: 1px dotted transparent;
  pointer-events: auto;
}

.back .designerHoverItem:hover {
  background-color: rgba(220, 220, 220, 0.6);
  cursor: pointer;
  border: 1px dotted black;
  pointer-events: auto;
}

.field-preview span,
.field-preview span span {
  color: inherit !important;
}
